<template>
  <div>
    <div class="search">
      <div class="title">活动列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="活动标题:">
          <el-input v-model="params.title"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getTableList()">查询</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
          <el-button
        type="primary"
        @click="$router.push({ path: '/addActivityList'})"
        >新增活动</el-button>
        </el-form-item>
      </el-form>
      <!-- <el-button
        type="primary"
        @click="$router.push({ path: '/addActivityList'})"
        style="margin-top: 20px"
        >新增活动</el-button> -->
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%; margin-top: 20px"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column prop="baseId" width="100" align="center" label="活动ID">
      </el-table-column>
      <el-table-column prop="title" label="活动标题" align="center">
      </el-table-column>
      <el-table-column prop="isAuto" label="发放模式" width="80" align="center">
      </el-table-column>

      <el-table-column
        prop="activeTime"
        label="活动时间"
        width="400"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="collectionPeriod"
        label="领取有效期"
        width="400"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="useDay"
        label="奖品使用时间（天）"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="strStatus"
        label="状态值"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <template >
            <el-button
              type="primary"
              size="mini"
              @click="edit(scope.row.baseId)"
              v-if="
                scope.row.status == 1 &&
                $store.state.powerList.indexOf('level:base:list:edit') !== -1
              "
              >编辑</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="onIsStart(scope.row.baseId, 2)"
              v-if="
                scope.row.status == 1 &&
                $store.state.powerList.indexOf('level:base:list:update') !== -1
              "
              >使失效</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="onIsStart(scope.row.baseId, 1)"
              v-if="
                scope.row.status == 2 &&
                $store.state.powerList.indexOf('level:base:list:update') !== -1
              "
              >启用</el-button
            >
          </template>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
  import { getDistributionActivityList, updateDistributionActivityStatus } from "../../api/wanxiaoShop";
export default {
  data() {
    return {
      total: 0,
      params: {
        currentPage: 1,
        pageSize: 5,
        title:''
      },
      tableData: [],
      type: "",
    };
  },
  created() {
    this.type = this.$route.query.type;
    this.getTableList();
  },
  watch: {
    $route(to, from) {
      this.type = this.$route.query.type;
      this.getTableList();
    },
  },
  methods: {
    async getTableList() {
      const { data } = await getDistributionActivityList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
        this.tableData.forEach((item) => {
          item.activeTime = item.beginTime + "--" + item.endTime;
          item.collectionPeriod =  item.receiveBeginTime + "--" + item.receiveEndTime;
        });
      }
    },
    onIsStart(baseId, status) {
      this.$confirm("是否操作这条数据", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let params = {
            baseId,
          status,
        };
       
        const { data } = await updateDistributionActivityStatus(params);
        if (data.code === 0) {
          this.$message({
            type: "success",
            message: data.msg,
          });
          this.getTableList();
        } else {
          this.$message({
            type: "error",
            message: data.msg,
          });
        }
      });
    },
    edit(baseId) {
      this.$router.push({ name: "addActivityList", query: { baseId} });
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getTableList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getTableList();
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>