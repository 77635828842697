var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"search"},[_c('div',{staticClass:"title"},[_vm._v("活动列表")]),_c('hr'),_c('el-form',{staticClass:"top",attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"活动标题:"}},[_c('el-input',{model:{value:(_vm.params.title),callback:function ($$v) {_vm.$set(_vm.params, "title", $$v)},expression:"params.title"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getTableList()}}},[_vm._v("查询")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ path: '/addActivityList'})}}},[_vm._v("新增活动")])],1)],1)],1),_c('el-table',{staticStyle:{"width":"100%","margin-top":"20px"},attrs:{"data":_vm.tableData,"border":"","tooltip-effect":"dark","row-key":_vm.rowKey,"align":"center"}},[_c('el-table-column',{attrs:{"prop":"baseId","width":"100","align":"center","label":"活动ID"}}),_c('el-table-column',{attrs:{"prop":"title","label":"活动标题","align":"center"}}),_c('el-table-column',{attrs:{"prop":"isAuto","label":"发放模式","width":"80","align":"center"}}),_c('el-table-column',{attrs:{"prop":"activeTime","label":"活动时间","width":"400","align":"center"}}),_c('el-table-column',{attrs:{"prop":"collectionPeriod","label":"领取有效期","width":"400","align":"center"}}),_c('el-table-column',{attrs:{"prop":"useDay","label":"奖品使用时间（天）","width":"150","align":"center"}}),_c('el-table-column',{attrs:{"prop":"strStatus","label":"状态值","width":"150","align":"center"}}),_c('el-table-column',{attrs:{"label":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [[(
              scope.row.status == 1 &&
              _vm.$store.state.powerList.indexOf('level:base:list:edit') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.edit(scope.row.baseId)}}},[_vm._v("编辑")]):_vm._e(),(
              scope.row.status == 1 &&
              _vm.$store.state.powerList.indexOf('level:base:list:update') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onIsStart(scope.row.baseId, 2)}}},[_vm._v("使失效")]):_vm._e(),(
              scope.row.status == 2 &&
              _vm.$store.state.powerList.indexOf('level:base:list:update') !== -1
            )?_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.onIsStart(scope.row.baseId, 1)}}},[_vm._v("启用")]):_vm._e()]]}}])})],1),_c('div',{staticClass:"block"},[_c('div',[_c('el-pagination',{attrs:{"current-page":_vm.params.currentPage,"page-sizes":[5, 10, 20],"page-size":_vm.params.pageSize,"layout":"total,sizes,prev,pager,next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }